<template>
  <admin-main-layout>
    <template slot="toolbar">
      <div
        id="history-index"
        class="container"
      >
        <el-row class="no-margin">
          <h1>{{ $t("page.history.title") }}</h1>
        </el-row>
        <el-row class="no-margin">
          <br>
        </el-row>
        <!-- input group over 846px -->
        <div class="input-group-wrapper">
          <el-col
            :span="18"
            class="accounting-filter-option"
          >
            <div class="date-time-picker">
              <date-time-picker
                v-model="filter.start"
                :minute-step="0"
                :hover-show="false"
                format-string="YYYY.MM.DD"
              />
            </div>
            <div class="date-time-picker">
              <date-time-picker
                v-model="filter.end"
                format-string="YYYY.MM.DD"
                :minute-step="0"
                :hover-show="false"
              />
            </div>

            <div class="drop-down">
              <el-select v-model="filter.user">
                <el-option
                  :label="$t('page.history.allDrones')"
                  :value="null"
                />
                <el-option
                  v-for="user in droneList"
                  :key="user.id"
                  :label="user.name"
                  :value="user.id"
                />
              </el-select>
            </div>
          </el-col>
          <el-col :span="6">
            <el-row
              type="flex"
              justify="end"
            >
              <el-button
                type="primary"
                class="main-actions"
                @click="loadData"
              >
                {{ $t("button.search") }}
              </el-button>
            </el-row>
          </el-col>
        </div>
        <!-- input group under 846px -->
        <div class="input-group-tablet-rwd">
          <div class="date-time-picker">
            <date-time-picker
              v-model="filter.start"
              :minute-step="0"
              :hover-show="false"
              format-string="YYYY.MM.DD"
            />
          </div>
          <div class="date-time-picker">
            <date-time-picker
              v-model="filter.end"
              format-string="YYYY.MM.DD"
              :minute-step="0"
              :hover-show="false"
            />
          </div>

          <div class="drop-down">
            <el-select v-model="filter.user">
              <el-option
                :label="$t('page.history.allDrones')"
                :value="null"
              />
              <el-option
                v-for="user in droneList"
                :key="user.id"
                :label="user.name"
                :value="user.id"
              />
            </el-select>
          </div>
          <el-button
            type="primary"
            class="main-actions"
            @click="loadData"
          >
            {{ $t("button.search") }}
          </el-button>
        </div>
      </div>
    </template>

    <data-table
      :data="source.items"
      :current-page="filter.page"
      :page-size="20"
      :total="source.paging.total"
      :loading="page.loading"
      :config="page.tableProps"
      :show-tool-bar="false"
      :table-props="{
        border: false,
        height: 'calc(100% - 74px)',
        'row-class-name': 'coretronic',
        'header-row-class-name': 'coretronic table-header'
      }"
      :pagination-props="{
        background: true,
        small: true,
        layout: 'prev, pager, next',
        pageSizes: [20],
        disabled: page.loading
      }"
      layout="table, pagination"
      @row-click="onHistoryClicked"
      @query-change="loadData"
    >
      <el-table-column
        v-for="columnProp in page.tableProps.tableColumnProps"
        :key="columnProp.prop"
        :type="columnProp.type"
        :label="$t(columnProp.label)"
        :column-key="columnProp.columnKey"
        :prop="columnProp.prop"
        :width="columnProp.width"
        :min-width="columnProp.minWidth"
        :sortable="columnProp.sortable"
        :resizable="columnProp.resizable"
        :formatter="columnProp.formatter"
        :align="columnProp.align"
        :header-align="columnProp.headerAlign"
        :class-name="`${columnProp.className} element--hover`"
        :label-class-name="columnProp.labelClassName"
      >
        <template scope="scope">
          <div
            v-if="columnProp.prop == 'thumbnail'"
            class="ima-cell"
          >
            <google-maps-thumbnail
              :src="scope.row.thumbnail"
              height="83px"
            />
          </div>
          <div v-else-if="columnProp.prop == 'mission_start_time'">
            {{ scope.row.mission_start_time | formatDate($t("formatter.dateTime")) }}
          </div>
          <div
            v-else-if="columnProp.prop == 'drone_id'"
            :title="scope.row[columnProp.prop]"
            class="tooltip"
          >
            {{ formatDroneName(scope.row[columnProp.prop]) }}
            <span class="tooltiptext">{{ scope.row[columnProp.prop] }}</span>
          </div>
          <div v-else-if="columnProp.prop == 'duration'">
            {{ getDuration(scope.row.mission_end_time, scope.row.mission_start_time) }}
          </div>
          <div
            v-else-if="columnProp.prop == 'result_code'"
            :class="`resultColumn ${scope.row[columnProp.prop] === 200 ? 'green' : 'red'}`"
          >
            {{ formateResult(scope.row[columnProp.prop]) }}
          </div>
          <div v-else-if="columnProp.prop == 'length'">
            {{ getDistance(scope.row[columnProp.prop]) }}
          </div>

          <div v-else-if="columnProp.prop == 'take_off'">
            {{ getHHMMSS(scope.row.mission_start_time) }}
          </div>

          <div v-else-if="columnProp.prop == 'landing'">
            {{ getLandingTime(scope.row.mission_end_time) }}
          </div>
          <div v-else-if="columnProp.prop == 'video'">
            <img
              v-show="scope.row.thumbnail"
              :src="scope.row.thumbnail"
              style=" height: 65px; width: 65px; "
            >
          </div>
          <div v-else-if="columnProp.prop == 'counting_report'">
            <a
              v-if="scope.row.counting"
              :href="`/api/v1/groups/${scope.row.group_id}/drones/${scope.row.drone_id}/histories/${scope.row.id}/material/detection`"
              :download="`report_${scope.row.drone_id}.csv`"
            >
              <fa-icon
                icon="file-csv"
                size="lg"
                class="csv-download"
              />
            </a>
            <slot />
          </div>
          <div v-else>
            {{ scope.row[columnProp.prop] }}
          </div>
        </template>
      </el-table-column>
    </data-table>
  </admin-main-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import duration from 'moment-duration-format';
import { CopyObject } from '@/utils/common';
import { FormatMissionPeriod } from '@/utils/mission';
import { DisMeasure } from '@/utils/measure';

import DataTable from '@/components/DataTable/DataTable.vue';
import tableProps from '@/config/tableProps/historyList';
import GoogleMapsThumbnail from '@/components/GoogleMaps/Thumbnail.vue';
import DateTimePicker from '@/components/DateTimePicker.vue';
import DroneApi from '@/services/api/domain/group/drone';
import GroupApi from '@/services/api/domain/group';
import histroyApi from '@/services/api/domain/group/histroy';

export default {
  name: 'FlightList',
  components: {
    DateTimePicker,
    DataTable,
    GoogleMapsThumbnail,
  },
  data() {
    return {
      googleMapKey: this.$config.googleMapKey,
      deleteShow: false,
      missionId: null,
      source: {
        items: [],
        paging: {
          total: 0,
        },
      },
      filter: {
        page: 1,
        type: null,
        user: null,
        start: moment().set({
          date: -11,
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        }),
        end: moment().set({
          hour: 23,
          minute: 59,
          second: 59,
          millisecond: 999,
        }),
      },
      page: {
        loading: false,
        selectedItems: [],
        tableProps,
      },
      mission: CopyObject(this.$store.state.mission.mission),
      periodUnit: this.$store.state.mission.periodUnit,
      assignMission: {},
      loadDataNonce: null,
      droneList: null,
    };
  },
  computed: {
    ...mapGetters({
      group: 'user/group',
      groupId: 'user/groupId',
      isAdmin: 'user/isAdmin',
    }),
  },
  async mounted() {
    if (this.groupId) {
      const droneFetch = await DroneApi.getAll(this.groupId);
      this.$set(this, 'droneList', droneFetch.data.drones);
    }
  },
  methods: {
    onHistoryClicked(row, s) {
      if (s.property === 'video') {
        if (row.thumbnail) {
          this.viewAllPhotos({ droneId: row.drone_id, historyId: row.id, groupId: row.group_id });
        }
      } else if (s.property === 'counting_report') {
        // console.log(s);
      } else {
        this.selectHistoryWithType(row.drone_id, row.id, row.result_code, 'rgb');
      }
    },
    selectHistoryWithType(drone, history, code, type) {
      if (code === 200) {
        /* let queryParams = {
          return: this.$router.currentRoute.name,
          returnQuery: JSON.stringify(this.filter),
        }; */
        const historyRequireParam = {
          droneId: drone,
          historyId: history,
          cameraType: type,
        };
        this.$router.push({
          name: 'playbackMap',
          params: historyRequireParam,
          query: historyRequireParam,
        });
      }
    },
    viewAllPhotos(data) {
      this.$router.push({
        name: 'pickAlbum',
        query: data,
      });
    },

    async loadData(filter) {
      if (!this.group) {
        return;
      }
      const localNonce = (this.loadDataNonce = new Object());
      // pageation config
      if (filter && filter.page && filter.pageSize) {
        this.filter.page = filter.page;
        this.filter.limit = filter.pageSize;
      }
      if (filter && filter.sort != null && filter.sort.prop != null) {
        this.filter.sort = `${filter.sort.prop}${filter.sort.order == 'descending' ? ':des' : ''}`;
      } else {
        this.filter.sort = 'mission_start_time:des'; // null;
      }

      try {
        this.page.loading = true;
        let o = {
          meta: null,
          data: null,
        };
        if (this.filter.user) {
          o = await DroneApi.history(this.group.id, this.filter.user, this.filter);
        } else {
          o = await GroupApi.history(this.group.id, this.filter);
        }

        if (localNonce !== this.loadDataNonce) {
          // a new call was made
          return;
        }
        const historyData = o.data.histoies;
        this.source.paging.total = o.data.total;

        this.source.items = historyData;
        this.page.loading = false;
        window.test = this;
      } catch (error) {
        this.$showFail(error);
        this.page.loading = false;
        // } finally {
        //   this.page.loading = false;
      }
    },
    formatDroneName(droneId) {
      const droneList = this.droneList || [];
      const found = droneList.find((element) => element.id == droneId);

      if (!found) {
        return 'N/A';
      }
      return found.name;
    },
    getDistance(srcDistance) {
      const dis = DisMeasure.display(srcDistance);
      return `${dis} ${DisMeasure.unit()}`;
    },
    FormatMissionPeriod,
    convertUTCDateToLocalDate(date) {
      const june = moment(date);
      return june.tz('Asia/Taipei').format('YYYY/MM/DD HH:mm');
    },
    getLandingTime(start) {
      let d = '';
      if (start) {
        d = new Date(start);
        d.setSeconds(d.getSeconds());
        d = this.getHHMMSS(d);
      }
      return d;
    },
    calcUploadPercentage(fraction, denominator) {
      if (denominator === 0) {
        return this.$t('page.history.imageNone');
      }
      return fraction > denominator ? '100%' : `${Math.ceil((fraction / denominator) * 100)}%`;
    },
    getHHMMSS(d) {
      const date = new Date(d);
      const hh = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
      const mm = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
      const ss = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
      return `${hh}:${mm}:${ss}`;
    },
    getDuration(end, start) {
      let dur = '';
      if (end && start) {
        const seconds = (Date.parse(end) - Date.parse(start)) / 1000;
        const durObj = moment.duration(seconds, 'seconds');
        dur = durObj.format('mm:ss', { trim: false });
      }
      return dur;
    },
    formateResult(code) {
      switch (code) {
        case 100:
          return '';
        case 200:
          return this.$t('page.history.recordStatus.200');
        case 404:
          return this.$t('page.history.recordStatus.404');
        case 405:
          return this.$t('page.history.recordStatus.405');
        case 406:
          return this.$t('page.history.recordStatus.406');
        case 407:
          return this.$t('page.history.recordStatus.407');
        case 408:
          return this.$t('page.history.recordStatus.408');
        case 409:
          return this.$t('page.history.recordStatus.409');
        case 410:
          return this.$t('page.history.recordStatus.410');
        case 411:
          return this.$t('page.history.recordStatus.411');
        default:
          return '';
      }
    },
    selectHistory(drone, history, code) {
      if (code === 200) {
        const historyRequireParam = {
          droneId: drone,
          historyId: history,
          cameraType: 'none',
        };
        this.$router.push({
          name: 'playbackMap',
          params: historyRequireParam,
          query: historyRequireParam,
        });
      }
    },
    uploadImage(history) {
      histroyApi.reUploadImage(history.group_id, history.drone_id, history.id);
    },
  },
  beforeRouteEnter(to, from, next) {
    // 判斷網址來源，如果跟 mission 無關那就留下 params 告訴 created 要清掉現有任務
    const pathFirstLevel = from.path.split('/')[1];
    if (pathFirstLevel.toLocaleLowerCase() !== 'mission') {
      to.params.clear = true;
    } else {
      to.params.clear = false;
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
.csv-download {
  width: 24px;
  height: 24px;
  color: gray;

&:hover {
  color: #67C23A;
}
}

.main-input {
  width: 236px;
}

.el-form--inline {
  .el-form-item {
    .el-form-item__content {
      width: 178px;
      .el-date-editor {
        width: 178px;
        .el-input__inner {
          padding-right: 0px;
        }
      }
    }
  }
}

.period-group {
  .el-input-number {
    // width: 95px;
  }
}

.el-dialog__wrapper.delete {
  .el-dialog {
    .el-dialog__header {
      padding: 0;
    }
  }
}

.action-button.load {
  background-image: url("/asserts/icon/missions-load-mission-icon-normal.svg");
  width: 48px;
}

.action-button.load:hover {
  background-image: url("/asserts/icon/missions-load-mission-icon-focus.svg");
}

.no-mission {
  height: 461px;
  border-style: dashed;
  border-color: #e1e1e1;
  border-radius: 13px;
  text-align: center;

  .no-mission__content {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
  }

  h3 {
    color: #a5a5a5;
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 22px;
  }

  p {
    color: rgba(165, 165, 165, 0.9);
  }

  button {
    margin-top: 66px;
    margin-bottom: 66px;
  }
}

.accounting-filter-option {
  > * {
    display: inline-block;
  }
  .drop-down {
    width: 132px;
  }
}

.cell {
  .resultColumn {
    display: flex;
    justify-content: space-between;
  }
  .green {
    color: #6fb666;
  }
  .red {
    color: #c90000;
  }
}

.vap {
  margin-top: 11px;
  margin-right: 25px;
  color: #b1b1b1;
}

.preview-image-link:link,
.vap:link {
  text-decoration: none;
}

.preview-image-link:visited,
.vap:visited {
  text-decoration: none;
}

.preview-image-link:hover,
.vap:hover {
  text-decoration: none;
  color: #333333;
}

.preview-image-link:active,
.vap:active {
  text-decoration: none;
}

/* drone name not found style */
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

#history-index {
  .input-group-wrapper {
    .date-time-picker {
      width: 23%;
      margin-right: 13px;
      max-width: 112px;
    }
    @include tablet-medium-846px {
      display: none;
    }
  }

  .input-group-tablet-rwd {
    display: none;
    @include tablet-medium-846px {
      @include justify-content-between;
      min-width: 475px;
      .date-time-picker,
      .drop-down,
      .more-actions {
        width: 25%;
      }
    }
  }
}
/deep/ .element--hover {
  &:hover {
    cursor: pointer;
  }
}
</style>
