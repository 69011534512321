export default {
  tableColumnProps: [
    {
      label: 'page.history.table.drone_name',
      prop: 'drone_id',
      width: null,
      minWidth: '70px',
      sortable: null,
      resizable: null,
      searchable: true,
      formatter: null,
      align: null,
      headerAlign: null,
      className: null,
      labelClassName: null,
      fixed: null,
      defaultSort: null,
    },
    {
      label: 'page.history.table.create_time',
      prop: 'mission_start_time',
      width: null,
      minWidth: '95px',
      sortable: null,
      resizable: null,
      searchable: true,
      formatter: null,
      align: null,
      headerAlign: null,
      className: null,
      labelClassName: null,
      fixed: null,
      defaultSort: null,
    },
    {
      label: 'page.history.table.take_off',
      prop: 'take_off',
      width: null,
      minWidth: '88px',
      sortable: null,
      resizable: null,
      searchable: true,
      formatter: null,
      align: null,
      headerAlign: null,
      className: null,
      labelClassName: null,
      fixed: null,
      defaultSort: null,
    },
    {
      label: 'page.history.table.landing',
      prop: 'landing',
      width: null,
      minWidth: '87px',
      sortable: null,
      resizable: null,
      searchable: true,
      formatter: null,
      align: null,
      headerAlign: null,
      className: null,
      labelClassName: null,
      fixed: null,
      defaultSort: null,
    },
    {
      label: 'page.history.table.distance',
      prop: 'length',
      width: null,
      minWidth: '98px',
      sortable: null,
      resizable: null,
      searchable: true,
      formatter: null,
      align: null,
      headerAlign: null,
      className: null,
      labelClassName: null,
      fixed: null,
      defaultSort: null,
    },
    {
      label: 'page.history.table.time',
      prop: 'duration',
      width: null,
      minWidth: '72px',
      sortable: null,
      resizable: null,
      searchable: true,
      formatter: null,
      align: null,
      headerAlign: null,
      className: null,
      labelClassName: null,
      fixed: null,
      defaultSort: null,
    },
    {
      label: 'page.history.table.result',
      prop: 'result_code',
      width: null,
      minWidth: '80px',
      sortable: null,
      resizable: true,
      searchable: true,
      formatter: null,
      align: null,
      headerAlign: null,
      className: null,
      labelClassName: null,
      fixed: null,
      defaultSort: null,
    },
    {
      label: 'page.history.table.video',
      prop: 'video',
      width: null,
      minWidth: '90px',
      sortable: null,
      resizable: true,
      searchable: true,
      formatter: null,
      align: null,
      headerAlign: null,
      className: null,
      labelClassName: null,
      fixed: null,
      defaultSort: null,
    },
    {
      label: 'page.history.table.report',
      prop: 'counting_report',
      width: '80px',
      minWidth: '80px',
      sortable: null,
      resizable: true,
      searchable: true,
      formatter: null,
      align: 'center',
      headerAlign: null,
      className: null,
      labelClassName: null,
      fixed: null,
      defaultSort: null,
    },
    // {
    //   label: 'page.history.table.upload_progress',
    //   prop: 'image_size',
    //   minWidth: '50px',
    //   sortable: null,
    //   resizable: true,
    //   searchable: true,
    //   formatter: null,
    //   align: null,
    //   headerAlign: null,
    //   className: null,
    //   labelClassName: null,
    //   fixed: null,
    //   defaultSort: null,
    // },
  ],
};
